<template>
    <section class="main-content-section">
        <div class="grid">

            <div class="col-8-12 push-2-12 sm-col-1-1 sm-push-0">
                <div v-if="order.lines.length > 0" class="register-product-summary">
                    <h2 class="page-title">{{selectedProducts}}</h2>
                    <div v-for="line in order.lines" :key="line.id" class="flex justify-space-between register-product">                     
                        <figure>
                            <img :src="line.imageUrl" :alt="line[`name${selectedLanguage}`]">
                        </figure>
                        <div class="flex flex-column justify-end">
                            <h4>{{line[`name${selectedLanguage}`]}}</h4>
                            <div class="flex justify-end">
                                <span>{{amount}}</span>
                                <span>{{line.quantity}}</span>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div class="col-8-12 push-2-12 sm-col-1-1 sm-push-0">
                <div class="form-container register-form">
                    <div class="form-header">
                        <h1 class="page-title">{{hello}} {{ username }}</h1>
                        <div class="info">{{weNeedYourEmailaddress}}</div>
                    </div>
                    
                    <form @submit.prevent="submitForm()">
                        <div v-if="isEmailVisible" class="form-row flex flex-column">
                            <label for="email">{{emailAddress}} <span v-if="isEmailRequired">*</span></label>
                            <template v-if="isEmailRequired">
                                <input 
                                    type="email" 
                                    id="email"
                                    v-model.trim="email"
                                    @input="$v.email.$touch()"
                                    :class="{ error: $v.email.$error }" 
                                    @blur="saveDataToLocalStorage('email', email)"
                                />
                                <div v-if="$v.email.$dirty">
                                    <p class="error" v-if="!$v.email.email">{{validEmailAddress}}</p>
                                    <p class="error" v-if="!$v.email.required">{{emailAddress}} {{isRequired}}</p>
                                </div>
                            </template>
                            <template v-else>
                                <input type="email" id="email" v-model.trim="email" @blur="saveDataToLocalStorage('email', email)" />
                            </template>
                        </div>

                        <div v-if="isEmailVisible" class="form-row flex flex-column">
                            <label for="emailConfirmation">{{confirmEmailaddress}} <span v-if="isEmailRequired">*</span></label>
                            <template v-if="isEmailRequired">
                                <input 
                                    type="email" 
                                    id="emailConfirmation"
                                    v-model.trim="emailConfirmation"
                                    @input="$v.emailConfirmation.$touch()"
                                    :class="{ error: $v.emailConfirmation.$error }"
                                    @blur="saveDataToLocalStorage('emailConfirmation', emailConfirmation)" 
                                />
                                <div v-if="$v.emailConfirmation.$dirty">
                                    <p class="error" v-if="!$v.emailConfirmation.required">{{confirmEmailaddress}} {{isRequired}}</p>
                                <p class="error" v-if="!$v.emailConfirmation.sameAsEmail">{{incorrectEmailAddressConfirmation}}</p>
                                </div>
                            </template>
                            <template v-else>
                                <input type="email" id="emailConfirmation" v-model.trim="emailConfirmation" @blur="saveDataToLocalStorage('emailConfirmation', emailConfirmation)" />
                            </template>
                        </div>

                        <div v-if="isPhoneVisible" class="form-row flex flex-column">
                            <label for="phone">{{phoneNumber}} <span v-if="isPhoneRequired">*</span></label>
                            <template v-if="isPhoneRequired">
                                <input 
                                    type="number" 
                                    id="phone" 
                                    v-model.trim="phone"
                                    @input="$v.phone.$touch()"
                                    :class="{ error: $v.phone.$error }"
                                    @blur="saveDataToLocalStorage('phone', phone)"
                                />
                                <div v-if="$v.phone.$dirty">
                                    <p class="error" v-if="!$v.phone.required">{{phoneNumber}} {{isRequired}}</p>
                                    <p class="error" v-if="!$v.phone.valid">{{invalidSpecialCharacters}}</p>
                                </div>
                            </template>
                            
                            <template v-else>
                                <input type="text" id="phone" v-model.trim="phone" @blur="saveDataToLocalStorage('phone', phone)"/>
                            </template>
                        </div>

                        <div v-if="isMobileVisible" class="form-row flex flex-column">
                            <label for="mobilePhone">{{mobileNumber}} <span v-if="isMobileRequired">*</span></label>
                            <template v-if="isMobileRequired">
                                <input 
                                    type="number" 
                                    id="mobilePhone" 
                                    v-model.trim="mobilePhone"
                                    @input="$v.mobilePhone.$touch()"
                                    :class="{ error: $v.mobilePhone.$error }"
                                    @blur="saveDataToLocalStorage('mobilePhone', mobilePhone)"
                                />
                                <div v-if="$v.mobilePhone.$dirty">
                                    <p class="error" v-if="!$v.mobilePhone.required">{{mobileNumber}} {{isRequired}}</p>
                                    <p class="error" v-if="!$v.mobilePhone.valid">{{invalidSpecialCharacters}}</p>
                                </div>
                            </template>

                            <template v-else>
                                 <input type="text" id="mobilePhone" v-model.trim="mobilePhone" @blur="saveDataToLocalStorage('mobilePhone', mobilePhone)" />
                            </template>
                        </div>

                        <div class="form-row">
                            <input type="checkbox" id="terms" v-model="terms" @change="handleCheckboxEvent('terms', terms)" :class="{ error: $v.terms.$error }">
                            <label for="terms"><div class="custom-checkbox"></div>{{accept}} <router-link to="/terms">{{termsAndConditions.toLowerCase()}}</router-link> *</label>
                            <div v-if="$v.terms.$invalid && $v.terms.$dirty">
                                <p class="error" v-if="$v.terms.required">{{termsAndConditions}} {{isRequired}}</p>
                            </div>
                        </div>

                        <div class="form-row">
                            <input type="checkbox" id="privacyPolicy" v-model="privacyPolicy" @change="handleCheckboxEvent('privacyPolicy', privacyPolicy)" :class="{ error: $v.privacyPolicy.$error }">
                            <label for="privacyPolicy"><div class="custom-checkbox"></div>{{accept}} <router-link to="/privacy-policy">{{privacyPolicyLabel.toLowerCase()}}</router-link> *</label>
                             <div v-if="$v.privacyPolicy.$invalid && $v.privacyPolicy.$dirty">
                                <p class="error" v-if="$v.privacyPolicy.required">{{privacyPolicyLabel}} {{isRequired}}</p>
                            </div>
                        </div>

                        <div class="form-row">
                            <span class="small">* {{indicatesRequiredField}}</span>
                        </div>

                        <div class="form-row flex justify-space-between">
                            <button @click="navigateToPreviousStep()" class="btn btn-secondary">{{previousStep}}</button>
                            <button type="submit" class="btn btn-primary alternative submitBtn">{{nextStep}} (2/2)</button>
                        </div>                      
                    </form>
                </div>
            </div>
        </div>
        <WarningMessageModal v-show="showWarningModal" :title="errorTitle" :text="errorMessage" @closeModal="closeModal()"/>
    </section>
</template>

<script>
import { mapGetters } from 'vuex';
import { required, email, sameAs } from 'vuelidate/lib/validators';
import WarningMessageModal from '@/components/modals/WarningMessageModal';
import agent from '../../api/agent';

export default {
    name: 'RegisterStepTwoStorePortal',
    components: {WarningMessageModal},
    data() {
        return {
            email: '',
            emailConfirmation: '',
            phone: '',
            mobilePhone: '',
            terms: true,
            privacyPolicy: true,
            errorTitle: '',
            errorMessage: '',
            showWarningModal: false
        }
    },

    created() {
        this.setDocumentTitle();

        if (!this.order && this.$route.query.orderId) {
            this.$store.dispatch('fetchOrder', this.$route.query.orderId);
        }

        this.email = this.$store.getters.getEmail || localStorage.getItem('email');
        this.emailConfirmation = this.$store.getters.getEmailConfirmation || localStorage.getItem('emailConfirmation');
        this.phone = this.$store.getters.getPhone || localStorage.getItem('phone');
        this.mobilePhone = this.$store.getters.getMobile || localStorage.getItem('mobilePhone');
    },

    updated() {
        this.setDocumentTitle();
    },
    
    computed: {
        ...mapGetters([
            'metaTitle',
            'selectedLanguage',
            // Setttings
            'isEmailVisible',
            'isEmailRequired',
            'isPhoneVisible',
            'isPhoneRequired',
            'isMobileVisible',
            'isMobileRequired',
            'recaptchaValidation',
            // Texts
            'hello',
            'weNeedYourEmailaddress',
            'emailAddress',
            'validEmailAddress',
            'confirmEmailaddress',
            'incorrectEmailAddressConfirmation',
            'phoneNumber',
            'mobileNumber',
            'previousStep',
            'nextStep',
            'indicatesRequiredField',
            'isRequired',
            'notAllRequiredFieldsAreCompleted',
            'amount',
            'selectedProducts',
            'createYourAccount',
            'invalidSpecialCharacters',
            'accept',
            'termsAndConditions',
        ]),
        ...mapGetters({
            username: 'getUsername',
            order: 'getOrder',
            orderId: 'getOrderId',
            privacyPolicyLabel: 'privacyPolicy',
        }),

    },

    methods: {
        setDocumentTitle() {
            document.title = this.createYourAccount + this.metaTitle;
        },

        showModal() {
            this.showWarningModal = true;
        },

        closeModal() {
            this.showWarningModal = false;
        },

        saveDataToStore() {
            this.$store.dispatch('setEmail', this.email);
            this.$store.dispatch('setEmailConfirmation', this.emailConfirmation);
            this.$store.dispatch('setPhone', this.phone);
            this.$store.dispatch('setMobile', this.mobilePhone);
            this.$store.dispatch('setTerms', this.terms);
            this.$store.dispatch('setPrivacyPolicy', this.privacyPolicy);
        },

        saveDataToLocalStorage(key, data) {
            localStorage.setItem(key, data);
        },

        handleCheckboxEvent(key, data) {
            this.$v[key].$touch();
            this.saveDataToLocalStorage(key, data);
        },

        navigateToPreviousStep() {
            this.saveDataToStore();
            this.$router.push(`/register/step-1?orderId=${this.$route.query.orderId}`);
        },

        parseJwt(token) {
            const base64Url = token.split('.')[1];
            const base64 = base64Url.replace(/-/g, '+').replace(/_/g, '/');
            const jsonPayload = decodeURIComponent(window.atob(base64).split('').map(c => {
                return '%' + ('00' + c.charCodeAt(0).toString(16)).slice(-2);
            }).join(''));
            return JSON.parse(jsonPayload);
        },

        removeAccountData() {
            localStorage.removeItem('firstName');
            localStorage.removeItem('lastName');
            localStorage.removeItem('email');
            localStorage.removeItem('emailConfirmation');
            localStorage.removeItem('phone');
            localStorage.removeItem('mobilePhone');
            localStorage.removeItem('cardNumber');
            localStorage.removeItem('collector');
            localStorage.removeItem('terms');
            localStorage.removeItem('privacyPolicy');
        },

        async registerUser() {
            const registerData = {
                firstName: this.$store.getters.getFirstname || localStorage.getItem('firstName'),
                lastName: this.$store.getters.getLastname || localStorage.getItem('lastName'),
                email: this.$store.getters.getEmail || localStorage.getItem('email'),
                password: this.password || localStorage.getItem('password'),
                phone: this.$store.getters.getPhone || localStorage.getItem('phone'),
                mobile: this.$store.getters.getMobile || localStorage.getItem('mobilePhone'),
                isDigitalSaver: this.$store.getters.getDigitalCollector || !!localStorage.getItem('collector'),
                acceptedTerms: this.terms && this.privacyPolicy || !!localStorage.getItem('terms') && !!localStorage.getItem('privacyPolicy'),
            }

            this.isLoading = true;
            
            try {
                const userId = await agent.service.registerUser(registerData);
                
                this.$store.dispatch('setUserId', this.userId);
                this.$store.dispatch('setAuthenticated', true);

                this.removeAccountData();
                    
                try {
                    const confirmedOrder = await agent.service.confirmOrder({
                        orderId: this.$route.query.orderId,
                        userId: userId,
                        languageNumber: this.$store.getters.selectedLanguage,
                        sendEmailConfirmation: true
                    });

                    this.isLoading = false;
                    this.$store.commit('CLEAR_ORDER');
                    this.$store.commit('REMOVE_USER');
                    this.$router.push(`/register/thank-you?orderId=${confirmedOrder}`);
                } catch (error) {
                    console.log(error.response);
                    this.isLoading = false;
                    this.errorTitle = error.response.data.title;
                    this.errorMessage = error.response.data.detail;
                    this.showModal();
                }
            } catch (error) {
                console.log(error.response);
                this.isLoading = false;
                this.errorTitle = error.response.data.title;
                this.errorMessage = error.response.data.detail;
                this.showModal();
            }
        },

        async validateRecaptcha(token) {
            await this.$store.dispatch('validateRecaptcha', token);
            if (this.recaptchaValidation === true) {
                this.registerUser();
            } else {
                this.isLoading = false;
                this.errorTitle = 'Something went wrong';
                this.errorMessage = 'Please try again';
                this.showModal();
            }
        },

        async getRecaptchaToken() {
            this.isLoading = true;
            await this.$recaptchaLoaded();
            const token = await this.$recaptcha('login');
            this.validateRecaptcha(token);
        },

        submitForm() {
            // Warning modal message handlers and prevent navigation to next step 
            if (this.isEmailVisible && this.isEmailRequired && this.$v.email.$invalid) {
                this.errorTitle = this.notAllRequiredFieldsAreCompleted;
                this.errorMessage = this.emailAddress + ' ' + this.isRequired;
                this.showModal();
                return;
            }

            if (this.isEmailVisible && this.isEmailRequired && this.$v.emailConfirmation.$invalid) {
                this.errorTitle = this.notAllRequiredFieldsAreCompleted;
                this.errorMessage = this.confirmEmailaddress + ' ' + this.isRequired;
                this.showModal();
                return;
            }

            if (this.isPhoneVisible && this.isPhoneRequired && this.$v.phone.$invalid) {
                this.errorTitle = this.notAllRequiredFieldsAreCompleted;
                this.errorMessage = this.phoneNumber + ' ' + this.isRequired;
                this.showModal();
                return;
            }

            if (this.isMobileVisible && this.isMobileRequired && this.$v.mobilePhone.$invalid) {
                this.errorTitle = this.notAllRequiredFieldsAreCompleted;
                this.errorMessage = this.mobileNumber + ' ' + this.isRequired;
                this.showModal();
                return;
            }

            if (this.$v.terms.$invalid) {
                this.errorTitle = this.notAllRequiredFieldsAreCompleted;
                this.errorMessage = this.termsAndConditions + ' ' + this.isRequired;
                this.showModal();
                return;
            }

            if (this.$v.privacyPolicy.$invalid) {
                this.errorTitle = this.notAllRequiredFieldsAreCompleted;
                this.errorMessage = this.privacyPolicyLabel + ' ' + this.isRequired;
                this.showModal();
                return;
            }
            
            // Store data in store if conditions are met
            this.saveDataToStore();

            const jwt = localStorage.getItem('token');
            const parsedToken = this.parseJwt(jwt);
            const role = parsedToken.role;
            
            
            if (role === 'Store') {
                // Skip password and make order
                if(!this.$v.terms.$invalid && !this.$v.privacyPolicy.$invalid) {
                    this.getRecaptchaToken();
                }
            } else {
                // Go to next step for password
                this.$router.push(`/register/step-3?orderId=${this.$route.query.orderId}`);
            }
        }
    },

    validations: {
        email: { email, required },
        emailConfirmation: { email, required, sameAsEmail: sameAs("email") },
        phone: { 
            required,
            valid: (value) => {
                const containsSpecial = /[#?!@$%^&*-/";|<>+=\\(){}]/.test(value);
                return(
                    !containsSpecial
                );
            } 
        },
        mobilePhone: { 
            required,
            valid: (value) => {
                const containsSpecial = /[#?!@$%^&*-/";|<>+=\\(){}]/.test(value);
                return(
                    !containsSpecial
                );
            } 
        },
        terms: { required, sameAsChecked: sameAs(() => true) },
        privacyPolicy: { required, sameAsChecked: sameAs(() => true) },
    },
}
</script>